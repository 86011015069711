define(['$window'], ($window) => {

  const swatchSelectSlider = () => {
    const component = {};

    const _config = {
      image: '.swatchSelectSlider_image',
      thumbnail: '.swatchSelectSlider_thumbnail_image',
      imageContainerSlider: '.swatchSelectSlider_image_container_slider',
      thumbnailButton: '.swatchSelectSlider_button',
      thumbnailContainer: '.swatchSelectSlider_thumbnail_container',
      text: '.swatchSelectSlider_title_container',
      active: 'active'
    };

    const _init = (element) => {
      component.element = element;
      component.imageContainerSlider = component.element.querySelector(component.config.imageContainerSlider);
      component.thumbnailContainer = component.element.querySelector(component.config.thumbnailContainer);
      component.images = component.element.querySelectorAll(component.config.image);
      component.thumbnails = component.element.querySelectorAll(component.config.thumbnail);
      component.thumbnailButton = component.element.querySelectorAll(component.config.thumbnailButton);
      component.image = component.element.querySelector(component.config.image);
      component.text = component.element.querySelectorAll(component.config.text);
      component.addlisteners();
      return component;
    };

    const _addlisteners = () => {
      let i = 0;
      component.thumbnails.forEach(thumbnail => {
          let position = i;
          i++;
          thumbnail.addEventListener('click', () => {
            component.thumbnailClick(position);
          });
        }
      );
    }

    const _thumbnailClick = (position) => {
      let imageWidth = component.image.clientWidth;
      let sliderPosition = (-imageWidth * position);
      component.imageContainerSlider.style.transform = 'translateX(' + sliderPosition + 'px)';
      component.thumbnailButton.forEach(thumbnail => {
        thumbnail.classList.remove(component.config.active)
      })
      component.thumbnailButton[position].classList.add(component.config.active);
      component.text.forEach(item => {
        item.classList.remove(component.config.active)
      })
      component.text[position].classList.add(component.config.active);
    }


    component.config = _config;
    component.init = _init;
    component.addlisteners = _addlisteners;
    component.thumbnailClick = _thumbnailClick;

    return component;
  };

  return swatchSelectSlider;
});
